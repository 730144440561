import React from 'react';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { useErrors } from '../../contexts/ErrorsContext';
import './PageAlert.css';

const PageAlert = () => {
  const { apiError, clearError } = useErrors();

  if (!apiError.message) return null;

  const getIcon = () => {
    if (apiError.name === 'Info') {
      return <div className='info-icon'>ℹ️</div>; // Green info icon
    }
    return <div className='alert-icon'>⚠️</div>; // Default alert icon
  };

  return (
    <ToastContainer position='top-end' className='p-3'>
      <Toast show className='custom-toast'>
        <Toast.Header closeButton={false}>
          {getIcon()}
          <strong className='me-auto'>{apiError.name}</strong>
          <Button
            variant='link'
            onClick={clearError}
            style={{ color: 'grey', marginRight: '-10px' }}
          >
            <span className='material-symbols-outlined'>close</span>
          </Button>
        </Toast.Header>
        <Toast.Body>{apiError.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default PageAlert;
